import React, { useEffect } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PageIntro from "../components/common/pageIntro/pageIntro"
import MainBannerImage from "../components/common/mainBanner/mainBannerImage"
import { graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-react-intl"
import { setSeoData } from "../services/seo.service"
import { getCarouselImageOG } from "../components/functions"
import GenericComponents from "../components/generic/genericComponents/genericComponents"
import BlkIntroWide from "../components/common/blockIntroWide/blockIntroWide"

export default function Activity({ data: { nodeActivity }, pageContext }) {


  useEffect(() => {

    //Scrollto section filter
    const hashtag = window.location.hash
    const scrollSectionClass = hashtag.split("#")
    if (hashtag) {
      setTimeout(() => {
        const section = document.querySelector("."+scrollSectionClass[1])
        section.scrollIntoView({ behavior: "smooth", block: "start" })
      }, 3000)
    }
  })

  const {
    title,
      field_seo,
    relationships: {
      activity_carousel: {
        relationships: { carousel_highlight_item: carouselMea },
      },
      activity_introduction: pageIntro,
      activity_components: components
    }
  } = nodeActivity

  const intl = useIntl()

  const componentsFiltered = components.filter(
    value => Object.keys(value).length !== 0
  )

  const carouselFiltered = carouselMea.filter(
    carousel => carousel.relationships.media !== null
  )

  const seoData = setSeoData({
    lang: intl.locale,
    title: title,
    field_seo: field_seo,
    image: process.env.GATSBY_URL + getCarouselImageOG(carouselFiltered),
  })

  return (
    <Layout pageContext={pageContext} pageTitle={title}>
       <Seo data={seoData} pageContext={pageContext} />
      {carouselFiltered.length > 0 && (
        <MainBannerImage items={carouselFiltered} />
      )}
      <BlkIntroWide content={pageIntro}/>
      <GenericComponents components={componentsFiltered} />
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    nodeActivity(id: { eq: $id }) {
      ...activityFields
    }
  }
`
