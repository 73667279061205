import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import "./blockIntroWide.scss"
import Heading from "../blockHeading/heading"
import HTMLReactParser from "html-react-parser"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Media from "../media"
import {Link} from "gatsby"

const BlkIntroWide = ({ content }) => {
  return (
    <section className="blk-introWide">
      <Container>
        <Row>
          <Col md={5}>
            <Heading
                caption={content.introduction_caption}
                title={content.introduction_title}
            />
            <div className="mediaIntro">
              {content.relationships.introduction_medias && (
                  <Media media={content.relationships.introduction_medias[0]} />
              )}
            </div>
          </Col>
          <Col md={6}>
              {content.introduction_description.processed && (
                  <div className="description">
                      {HTMLReactParser(content.introduction_description.processed)}
                  </div>
              )}
              {content.introduction_link && (
                  <Link target={"_blank"} className="simpleLink" to={content.introduction_link.uri}>
                      {content.introduction_link.title}
                  </Link>
              )}
          </Col>
        </Row>
      </Container>
      <Container fluid>
        {content.relationships.introduction_medias[1] && (
            <GatsbyImage
                image={getImage(content.relationships.introduction_medias[1].relationships.field_image.gatsbyImage )}
            />
        )}
      </Container>
    </section>
  )
}

export default BlkIntroWide
